import { template as template_d8f24a0809d0473e8f25afd875da0f89 } from "@ember/template-compiler";
const FKLabel = template_d8f24a0809d0473e8f25afd875da0f89(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
